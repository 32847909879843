import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useUpload from '../hooks/useUpload';
import Loader from './Loader';
import UploadFileItem from './UploadFileItem';


const { Dragger } = Upload;
const UploadField = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('id');
  const [selectedFile, setSelectedFile] = useState();
  const [isLoadingAction,setIsLoadingAction] = useState(false)

  const { files, createFile, deleteFile, isFetching } = useUpload({
    projectId: userId,
    setActionLoading: () => setIsLoadingAction(false)
  });

  const handleUpload = () => {
    const formData = new FormData()
    formData.append('file',selectedFile)
    createFile.mutate({
      data:formData,
      onSuccess: () => setSelectedFile(null)
    })
    setIsLoadingAction(true)
  };

  const handleChange = (e) => {
    setSelectedFile(e.file);
  };

  const handleRemove = (uid) => {
    deleteFile.mutate(uid)
  }

  const ChangedFile = () => (
    <div className="upload-file_container">
      <div className="upload-file_container-box">
        <b>{selectedFile?.name}</b>
      </div>
      <span
        className="upload-file_container-trash"
        onClick={() => setSelectedFile(null)}
      >
        <DeleteOutlined />
      </span>
    </div>
  );
  
  return (
    <>
      {isFetching && <Loader/>}
      <Dragger customRequest={handleChange} maxCount={1} showUploadList={false}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
      {selectedFile && <ChangedFile />}
      <Button
        className="styled-button"
        onClick={handleUpload}
        style={{ marginTop: 16, marginLeft: 0 }}
        disabled={!selectedFile}
        loading={isLoadingAction}
      >
        Upload file
      </Button>
      {
        files?.length>0 &&
        <Upload
          fileList={[...files]}
          className="upload-list-inline"
          onRemove={handleRemove}
          itemRender={(_, file) => 
            (<UploadFileItem 
                item={file} 
                handleRemove={handleRemove}
              />)
          }
        ></Upload>
      }
    </>
  );
};

export default UploadField;
