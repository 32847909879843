import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { qstAPI } from '../services/api';
import useToast from './useToast';

const code = 'DiQC3QEDDbt07PE4WmUNqrD8NElL_8X00WBJo1b4ziARAzFubN-fdA==';

const normalizer = (item) => ({
  uid: item.documentId,
  name: item.fileName,
  status: 'done',
  url: item.url,
  thumbUrl: item.url,
  ...item
});
const postFile = ({ id, data, onSuccess, onError }) =>
qstAPI
    .post(`api/Projects/${id}/Documents?code=${code}`, data)
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });

const getFiles = (id) =>
qstAPI
    .get(`api/Projects/${id}/Documents?code=${code}`)
    .then((res) => res.data);

const deleteFile = ({ id, documentId, onSuccess, onError }) =>
qstAPI
    .delete(
      `api/Projects/${id}/Documents?documentId=${documentId}&code=${code}`
    )
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });

const useUpload = ({ projectId, setActionLoading }) => {
  const { toast } = useToast();

  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ['FILES'],
    () => getFiles(projectId)
  );

  const mutationCreate = useMutation((payload, { onSuccess = () => {} } = {}) =>
    postFile({
      id: projectId,
      data: payload.data,
      onSuccess: () => {
        refetch();
        toast.success('File successfully uploaded!');
        payload.onSuccess();
        setActionLoading();
      },
      onError: () => {
        toast.error('Error in uploading file!');
        setActionLoading();
      }
    })
  );

  const mutationDelete = useMutation((payload, { onSuccess = () => {} } = {}) =>
    deleteFile({
      id: projectId,
      documentId: payload,
      onSuccess: () => {
        refetch();
        toast.success('File successfully deleted!');
        setActionLoading();
      },
      onError: () => {
        toast.error('Error in deleting file!');
        setActionLoading();
      }
    })
  );

  const parsedData = useMemo(
    () => data?.map((file) => normalizer(file)),
    [data]
  );

  return {
    error,
    isLoading,
    isFetching,
    files: parsedData,
    createFile: mutationCreate,
    deleteFile: mutationDelete
  };
};

export default useUpload;
