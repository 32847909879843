export const BASE_URL = 'https://rfa-survey-api.azurewebsites.net';
export const Questionnaire_BASE_URL = 'https://rfa-survey-api.azurewebsites.net';
export const fieldTypes = {
  MULTI_SELECT_LIST: 'MultiSelectList',
  DATE: 'Date',
  DROPDOWN: 'Dropdown',
  BOOLEAN: 'Boolean',
  TEXT: 'Text',
  UPLOAD: 'Upload',
  SUBMIT_BUTTON: 'SubmitButton',
  RATE:"Rating"
};
export const successTextPlaceholder = {
  submitted: 'Your survey has been submitted!',
  done: `The questionnaire has already been submitted, thank you. Please contact
  RFA for any questions.`
};
export const apiKey="bwRBR5fN56tRF_VWQyIx1FYNx6CntTXDdS_3vOqE7dlhAzFuql31kQ=="
export const postApiKey="bwRBR5fN56tRF_VWQyIx1FYNx6CntTXDdS_3vOqE7dlhAzFuql31kQ=="