import React from "react";

const NotFound = () => {
  return (
    <div className="maindiv">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h3>
          The page you are looking for is not found. Please contact
          Administrator
        </h3>
      </div>
    </div>
  );
};

export default NotFound;
