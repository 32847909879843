import axios from "axios";
import { BASE_URL } from "../constants";

export const qstAPI = (token?: any, url?: any) =>
  axios.create(
    token
      ? {
          baseURL: url ?? BASE_URL,
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {
          baseURL: BASE_URL,
          validateStatus: () => {
            return true;
          },
        }
  );

const getQuestionnaire = (inviteId: string, code: string) => {
  return qstAPI().get(`api/Questionnaire/${inviteId}?code=${code}`);
};

const getQuestionnaireInfo = (inviteId: string, code: string) => {
  return qstAPI().get(`api/Questionnaire/${inviteId}/Info?code=${code}`);
};
const saveQuestionnaire = (inviteId: string, code: string, data: any) => {
  return qstAPI().post(`api/Questionnaire/${inviteId}/Info?code=${code}`, data);
};
const updateQuestionnaire = (inviteId: string, code: string, data: any) => {
  return qstAPI().patch(
    `api/Questionnaire/${inviteId}/Info?code=${code}`,
    data
  );
};

export {
  getQuestionnaire,
  getQuestionnaireInfo,
  saveQuestionnaire,
  updateQuestionnaire,
};
