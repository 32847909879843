import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';

const UploadFileItem = ({item,handleRemove}) => {
    return (
        <div  className="files-list-item">
            <a href={item.url} download className='files-list-item_container'>
                <div className='files-list-item_icon'>
                    <PaperClipOutlined />
                </div>
                <div className='files-list-item_box'>
                    <span className='files-list-item_name'>{item.name}</span>
                    <span className='files-list-item_size'>{parseFloat(String(item.size/1024/1024)).toFixed(2)} MB</span>
                </div>
            </a>
            <div className='files-list-item_trash' onClick={() => handleRemove(item.uid)}>
                <DeleteOutlined/>
            </div>
        </div>
    )
}

export default UploadFileItem