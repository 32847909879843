export const tenantId = "77700dab-0afc-4bd8-acbe-e82b7cd6285b";
export const redirectUri = 'https://rfa-questionnaire.azurewebsites.net';
//export const redirectUri = "http://localhost:3000/";
export const clientId = "5e06f936-616e-42fd-acc6-616e67340aac";
export const authority = `https://login.microsoftonline.com/${tenantId}`;

export const msalConfig = {
  auth: {
    clientId,
    authority,
    redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [
    // 'User.Read',
    "api://rfareport-services/access_as_user",
  ],
};
