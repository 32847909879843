import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App.tsx';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/msal';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './config/queryClient';
import { BrowserRouter as Router } from 'react-router-dom';



export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.Fragment>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
          </Router>
      </QueryClientProvider>
    </MsalProvider>
  </React.Fragment>,
  document.getElementById('root')
);



