import { Navigate, Outlet } from "react-router-dom";
import Main from "../components/Main";
import NotFound from "./NotFound";
import { ManageLayout } from "./ManageLayout";
import ManageTemplates from "../components/ManageTemplates";
import ManageCampaigns from "../components/ManageCampaigns";
export const routes = [
  {
    path: "",
    element: <NotFound />,
  },
  {
    path: "/:id",
    element: <Main></Main>,
  },
  // {
  //   path: "/admin",
  //   element: <ManageLayout />,
  //   children: [
  //     {
  //       index: true,
  //       element: <Navigate to="/admin/templates" />,
  //     },
  //     {
  //       path: "templates",
  //       element: <ManageTemplates/>,
  //     },
  //     {
  //       path: "campaigns",
  //       element: <ManageCampaigns/>,
  //     },
  //     {
  //       path: "invites",
  //       element: <div>Templates</div>,
  //     },
  //     {
  //       path: "reports",
  //       element: <div>Templates</div>,
  //     },
  //   ],
  // },
  {
    path: "*",
    element: <NotFound />,
  },
];
