const Title = ({title,questionnaireObj}: any) => {
  const{TemplateStyle}=questionnaireObj;
  return (
      <div>
        {/* <h1 style={{textAlign: 'left', borderBottom: '1px solid #121245', color: '#121245'}}>{title}</h1> */}
        <h1 style={{textAlign: 'left', borderBottom: '1px solid #121245', color:`${!TemplateStyle?.color?"#000":TemplateStyle?.color}` ,fontSize:`${TemplateStyle?.Headings?.fontSize}`}}>{title}</h1>
      </div>
  );
}

export default Title;
