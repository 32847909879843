import { Button, Form } from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fieldTypes } from '../constants';
import useToast from '../hooks/useToast';
import FieldTypes from './FieldTypes';
import Loader from './Loader';
import Title from './Title';
import { apiKey,postApiKey } from '../constants';
import { saveQuestionnaire, updateQuestionnaire } from '../services/api';

type QuestionFormProps = {
  data: any;
  handleSubmit: Function;
  initVal: any;
  questionnaireObj:any
};

const QuestionForm: React.FC<QuestionFormProps> = ({
  data,
  handleSubmit,
  initVal,
  questionnaireObj
}) => {
  const { toast } = useToast();
  const [form] = Form.useForm();
  const [page, setPage] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fields, setField] = useState(data);
  const [currentValues, setCurrentValues] = useState({});
  const [mutatedValues, setMutatedValues] = useState<any>({});
  const [values, setAllValues] = useState({});
  const [searchParams] = useSearchParams();
  // const userId = searchParams.get('id');
  const params=useParams()
  const pages = fields.template?.pages;
  const hasSubmit = useMemo(
    () =>
      !!pages[page]?.sections
        .map((fields: any) =>
          fields.fields
            .filter((field: any) => field.active)
            .find((field: any) => field.type === fieldTypes.SUBMIT_BUTTON)
        )
        .filter((field: any) => field).length,
    [page]
  );

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [isNotValidated, setIsNotValidated] = useState(false);

  useEffect(() => {
    if (!isEmpty(data)) {
      setField(data);
      loadFieldValues(0);
    }
  }, [data]);

  const loadFieldValues = (currentPage: number) => {
    const initHandleChangeValue = (property: any, parentField: any) => {
      const newFields = { ...fields };
      const interestId = property.show;

      if (interestId && !interestId.includes(',')) {
        const section = newFields.template.pages[currentPage].sections.find(
          (item: any) => item.fields.find((f: any) => f.id === interestId)
        );
        const field = section.fields.find((f: any) => f.id === interestId);

        // Activate relative field
        field.active = true;
      }

      if (parentField && parentField.relativeFields) {
        const parentSection = newFields.template.pages[
          currentPage
        ].sections.find((item: any) =>
          item.fields.find((f: any) => f.id === parentField.id)
        );

        for (let i = 0; i < parentField.relativeFields.length; i++) {
          let relativeField = parentSection.fields.find(
            (f: any) => f.id === parentField.relativeFields[i]
          );
          relativeField.active = false;

          for (let j = 0; j < parentField.activeRelativeFields.length; j++) {
            if (relativeField.id === parentField.activeRelativeFields[j]) {
              relativeField.active = true;
              break;
            }
          }
        }
      }

      setField(newFields);
    };

    pages[currentPage]?.sections.forEach((section: any) =>
      section.fields
        .filter((field: any) => field.active)
        .forEach((field: any) => {
          let val = initVal[field.id];
          if (!val) return;

          switch (field.type) {
            case fieldTypes.MULTI_SELECT_LIST: {
              field.activeRelativeFields = [];

              for (let i = 0; i < val.length; i++) {
                let active = field.options.find(
                  (searchVal: any) => searchVal.value === val[i]
                );
                if (active) {
                  if (active.show.includes(',')) {
                    let relativeFields = active.show.split(',');

                    for (let j = 0; j < relativeFields.length; j++)
                      field.activeRelativeFields.push(relativeFields[j]);
                  } else {
                    field.activeRelativeFields.push(active.show);
                  }

                  initHandleChangeValue(active, field);
                }
              }

              break;
            }
            case fieldTypes.DROPDOWN: {
              field.activeRelativeFields = [];
              let active = field.options.find(
                (searchVal: any) => searchVal.value === val
              );
              if (active) {
                if (active.show.includes(',')) {
                  let relativeFields = active.show.split(',');

                  for (let j = 0; j < relativeFields.length; j++)
                    field.activeRelativeFields.push(relativeFields[j]);
                } else {
                  field.activeRelativeFields.push(active.show);
                }

                initHandleChangeValue(active, field);
              }
              break;
            }
            case fieldTypes.BOOLEAN: {
              let label = 'Unknown';

              if (val === true) label = 'Yes';

              if (val === false) label = 'No';

              field.activeRelativeFields = [];
              let active = field.options.find(
                (searchVal: any) => searchVal.value === label
              );

              if (active) {
                if (active.show.includes(',')) {
                  let relativeFields = active.show.split(',');

                  for (let j = 0; j < relativeFields.length; j++)
                    field.activeRelativeFields.push(relativeFields[j]);
                } else {
                  field.activeRelativeFields.push(active.show);
                }
              }

              initHandleChangeValue(active, field);
              break;
            }
            default:
          }
        })
    );
  };

  const handleChangeValue = (property: any, parentField: any) => {
    const newFields = { ...fields };
    const interestId = property.show;
    const mutatedVals: any = { ...mutatedValues };

    if (interestId && !interestId.includes(',')) {
      const section = newFields.template.pages[page].sections.find(
        (item: any) => item.fields.find((f: any) => f.id === interestId)
      );
      const field = section.fields.find((f: any) => f.id === interestId);

      // Activate relative field
      field.active = true;
    }

    if (parentField && parentField.relativeFields) {
      const parentSection = newFields.template.pages[page].sections.find(
        (item: any) => item.fields.find((f: any) => f.id === parentField.id)
      );

      for (let i = 0; i < parentField.relativeFields.length; i++) {
        let relativeField = parentSection.fields.find(
          (f: any) => f.id === parentField.relativeFields[i]
        );
        relativeField.active = false;
        mutatedVals[relativeField.id] = null;
        form.setFieldsValue({ [relativeField.id]: '' });

        for (let j = 0; j < parentField.activeRelativeFields.length; j++) {
          if (relativeField.id === parentField.activeRelativeFields[j]) {
            relativeField.active = true;
            break;
          }
        }
      }
    }

    setMutatedValues({
      ...mutatedValues,
      ...mutatedVals
    });
    setField(newFields);
  };
  useEffect(() => {
    if(initVal)
    form.setFieldsValue(initVal)
   }, [Object.keys(initVal||{}).length ,form])

   const fieldItems = pages[page]?.sections.map((fields: any) =>
    fields.fields
      .filter((field: any) => field.active)
      .map((field: any) => {
        return <FieldTypes field={field} onChange={handleChangeValue}  templateStyle={questionnaireObj?.TemplateStyle}/>;
      })
  );

  useEffect(() => {
    const header = document.getElementById('header');
    const fields = pages?.[page]?.sections?.map((fields: any) =>
      fields.fields.filter((field: any) => field.active)
    );

    header?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });

    fields[0]?.forEach((field: any) => {
      if (
        ['dateCompleted', 'dateOfSeparation'].includes(field.id) &&
        !mutatedValues[field.id]
      ) {
        const currentDate = initVal[field.id]
          ? moment(initVal[field.id])
          : undefined;
        form.setFieldsValue({
          [field.id]: currentDate
        });
        setMutatedValues((prev: any) => ({
          ...prev,
          [field.id]: prev[field.id] || currentDate
        }));
      }
    });
  }, [page]);

  const fieldsNameList = pages?.[page]?.sections
    ?.map((fields: any) =>
      fields.fields.filter((field: any) => field.active)
    )?.[0]
    ?.map((item: any) => item.id);

  const isNextDisabled = isNotValidated || page === pages.length - 1;

  const onFinish = (value: any) => {
    setAllValues({ ...values, ...value });
    submitQuestionnaire();
  };
const getUpDatedObj = (initVal: any, mutatedValues: any) => {
  let combinedRes: any = {};
  Object.keys(initVal).forEach((val) => {
    if (!mutatedValues[val]) {
      combinedRes[val] = initVal[val];
    } else {
      combinedRes[val] = mutatedValues[val];
    }
  });
  return { ...combinedRes, ...mutatedValues };
};
  const saveFilledInfo = () => {
    if (Object.keys(currentValues).length > 0) {
      setIsSaveLoading(true);
      // if (Object.keys(currentValues).length > 0) {
      //   setIsSaveLoading(true);
      //   axios
      //     .patch(url(userId), mutatedValues)
      //     .then(() => {
      let newObject = {
        SaveType: hasSubmit ? "Final" : "Incremental",
        QuestionnaireResult: Object.keys(initVal).length>0?getUpDatedObj(initVal,mutatedValues):mutatedValues,
      };
      updateQuestionnaire(params.id as string,postApiKey, newObject)
        .then(() => {
          setAllValues({ ...values, ...currentValues });
          // setMutatedValues({});
          toast.success('Survey successfully saved!');
        })
        .catch((err) => {
          toast.error(
            'Error in saving survey! Please verify fields before submit'
          );
        })
        .finally(() => {
          setIsSaveLoading(false);
        });
    }
  };

  const submitQuestionnaire = () => {
    setSubmitLoading(true);
    const newVals = { ...currentValues, ...values, ...mutatedValues };
let newObject = {
  SaveType: hasSubmit ? "Final" : "Incremental",
  QuestionnaireResult: newVals,
};
saveQuestionnaire(params.id as string, apiKey, newObject)
  .then(() => handleSubmit(true))
  .finally(() => setSubmitLoading(false));
  };

  const onHandleNextButton = () => {
    form.validateFields().then(() => {
      setPage(page + 1);
      loadFieldValues(page + 1);
    });
  };

  const handleSave = () => {
    form.validateFields().then(() => {
      saveFilledInfo();
    });
  };

  const onValuesChange = (values: any, allValues: any) => {
    setAllValues({ ...values, ...currentValues });
    setCurrentValues(allValues);
    setTimeout(
      () =>
        setMutatedValues((prev: any) => ({
          ...prev,
          ...values
        })),
      200
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsNotValidated(getErrorsCount() > 0);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentValues, page]);

  const getErrorsCount = () => {
    const fields = form.getFieldsError(fieldsNameList);
    let errorsCount = 0;
    fields.forEach((field: any) => (errorsCount += field?.errors?.length));
    return errorsCount;
  };

  return (
    <div style={{ textAlign: 'left' }}>
      {(isSaveLoading || submitLoading) && <Loader />}
      <Form
        form={form}
        layout="vertical"
        initialValues={initVal}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Title title={pages[page].title} questionnaireObj={questionnaireObj}/>
        {fieldItems}
        <div className="button-container">
          <div>
            {(page !== 0) &&<Button
              onClick={() => setPage(page - 1)}
              className="styled-button"
              disabled={page === 0}
              style={{
                marginLeft: 0
              }}
            >
              Back
            </Button>}

            {page !== pages.length - 1 &&<Button
              onClick={() => !isNextDisabled && onHandleNextButton()}
              className="styled-button"
              disabled={isNextDisabled}
              style={{
                marginLeft: page === 0?0:"initial"
              }}
            >
              Next
            </Button>}
          </div>
          {hasSubmit ? (
            <Button
              htmlType="submit"
              className="styled-button"
              style={{
                marginRight: 0
              }}
            >
              Submit
            </Button>
          ) : (
            questionnaireObj.inviteType!=="AnonymousInvite" &&<Button
              onClick={() => !isNotValidated && handleSave()}
              className="styled-button"
              disabled={isNotValidated || questionnaireObj.inviteType==="AnonymousInvite"}
              loading={isSaveLoading}
              style={{
                marginRight: 0
              }}
            >
              Save
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

QuestionForm.defaultProps = {
  data: null,
  handleSubmit: () => {},
  initVal: null
};

export default QuestionForm;
