import toast from 'react-hot-toast';
import { ReactComponent as Success } from '../assets/icon/check_round.svg';
import { ReactComponent as Dismiss } from '../assets/icon/cross.svg';
import { ReactComponent as Danger } from '../assets/icon/danger.svg';
import { ReactComponent as Warning } from '../assets/icon/warning.svg';

const commonStyle = {
  border: 'none',
  borderRadius: 4,
  zIndex: 1999
};

const successOptions = {
  style: {
    ...commonStyle,
    background: '#D4F2E9'
  }
};

const warningOptions = {
  style: {
    ...commonStyle,
    background: '#FCECD6'
  }
};

const errorOptions = {
  style: {
    ...commonStyle,
    background: '#fdeeee'
  }
};

const customMessage = (t, message) => (
  <>
    <span className="chatgenie-toast_message">{message}</span>
    <span className="chatgenie-toast_dismiss-btn">
      <Dismiss onClick={() => toast.dismiss(t.id)} />
    </span>
  </>
);

const useToast = () => {
  const toastSuccess = (message) =>
    toast.success((t) => customMessage(t, message), {
      ...successOptions,
      icon: <Success />
    });

  const toastDanger = (message) =>
    toast.success((t) => customMessage(t, message), {
      ...errorOptions,
      icon: <Danger />
    });

  const toastWarning = (message) =>
    toast.success((t) => customMessage(t, message), {
      ...warningOptions,
      icon: <Warning />
    });

  const toastify = {
    success: (message) => toastSuccess(message),
    warn: (message) => toastWarning(message),
    error: (message) => toastDanger(message)
  };

  return {
    toast: toastify
  };
};

export default useToast;
