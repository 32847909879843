import isEmpty from 'lodash/isEmpty';
import rfaLogo from '../assets/images/rfa-logo-white.svg'

const Header = ({ title,questionnaireObj }: any) => {
  const{TemplateStyle}=questionnaireObj;
  return (
    <div id="header">
      <img  src={`${TemplateStyle?.logo}` || rfaLogo} style={{ width: 200, textAlign: 'left' }} alt=""/>
      <h1 style={{ textAlign: 'center', padding: '40px 0',color:`${!TemplateStyle?.color?"#000":TemplateStyle?.color}` ,fontSize:`${TemplateStyle?.Headings?.fontSize}`}}>
        {!isEmpty(title) && title.template.name}
      </h1>
    </div>
  );
};

export default Header;
