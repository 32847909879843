import { DatePicker, Form, Input, Select ,Rate} from 'antd';
import parse from 'html-react-parser';
import { fieldTypes } from '../constants';
import UploadField from './UploadField';

const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'MM/DD/YYYY';

const FieldTypes = ({ field, onChange,templateStyle }: any) => {
  const handleChange = (value: string) => {
    field.activeRelativeFields = [];
    const active = field.options.find(
      (searchVal: any) => searchVal.value === value
    );
    if (active.show.includes(',')) {
      let relativeFields = active.show.split(',');

      for (let j = 0; j < relativeFields.length; j++)
        field.activeRelativeFields.push(relativeFields[j]);
    } else {
      field.activeRelativeFields.push(active.show);
    }

    onChange(active, field);
  };

  const handleBoolFieldChange = (value: boolean) => {
    let label = 'Unknown';

    if (value === true) label = 'Yes';

    if (value === false) label = 'No';

    field.activeRelativeFields = [];
    const active = field.options.find(
      (searchVal: any) => searchVal.value === label
    );
    if (active.show.includes(',')) {
      let relativeFields = active.show.split(',');

      for (let j = 0; j < relativeFields.length; j++)
        field.activeRelativeFields.push(relativeFields[j]);
    } else {
      field.activeRelativeFields.push(active.show);
    }

    onChange(active, field);
  };

  const handleMultiFieldChange = (value: any[]) => {
    field.activeRelativeFields = [];

    for (let i = 0; i < value.length; i++) {
      const active = field.options.find(
        (searchVal: any) => searchVal.value === value[i]
      );
      if (active.show.includes(',')) {
        let relativeFields = active.show.split(',');

        for (let j = 0; j < relativeFields.length; j++)
          field.activeRelativeFields.push(relativeFields[j]);
      } else {
        field.activeRelativeFields.push(active.show);
      }

      onChange(active, field);
    }

    if (value.length === 0) onChange({}, field);
  };

  const generateField = (param: any) => {
    switch (param.type) {
      case fieldTypes.DATE:
        return (
          <DatePicker
            format={dateFormat}
            key={param.id}
            style={{ width: 200 }}
            defaultValue={undefined}
          />
        );
      case fieldTypes.DROPDOWN:
        return (
          <Select placeholder="Please select" onChange={handleChange}>
            {param.options.map((option: any) => (
              <Option value={option.value} key={option.value}>
                {option.value}
              </Option>
            ))}
          </Select>
        );
      case fieldTypes.RATE:
        return (
          <Rate
            onChange={(value) => {
              return onChange(value, field);
            }}
          />
        );
      case fieldTypes.BOOLEAN:
        return (
          <Select onChange={handleBoolFieldChange}>
            {param.options.map((option: any) => {
              let val;

              if (option.value === "Yes") val = true;
              else if (option.value === "No") val = false;
              else val = "Unknown";

              return (
                <Option value={val} key={option.value}>
                  {option.value}
                </Option>
              );
            })}
          </Select>
        );
      case fieldTypes.MULTI_SELECT_LIST:
        return (
          <Select
            mode="multiple"
            allowClear
            placeholder="Please select"
            onChange={handleMultiFieldChange}
          >
            {param?.options.map((option: any) => {
              return (
                <Option value={option.value} key={option.value}>
                  {option.value}
                </Option>
              );
            })}
          </Select>
        );
      case fieldTypes.TEXT:
        return <TextArea rows={5} key={param.id} />;
      case fieldTypes.UPLOAD:
        return <UploadField />;
      case fieldTypes.SUBMIT_BUTTON:
        return <></>;
      default:
        return <Input />;
    }
  };

  return (
    <>
      {field.active && field.type !== 'Lable' && (
        <Form.Item
          label={field.type !== 'SubmitButton' && field.title}
          key={field.id}
          name={field.id}
          style={{'--label-fontSize':templateStyle?.Labels?.fontSize,'--color-white':templateStyle?.color}  as React.CSSProperties}
          rules={[
            {
              required: field.required,
              message: `Please input your "${field.title}" field!`
            }
          ]}
        >
          {generateField(field)}
        </Form.Item>
      )}

      {field.active && field.type === 'Lable' && (
        <Form.Item style={{...templateStyle,fontSize:templateStyle?.Labels?.fontSize,fontWeight:templateStyle?.Labels?.fontWeight,'--color-white':templateStyle?.color  as React.CSSProperties}}>{parse(field.title)}</Form.Item>
      )}
    </>
  );
};

export default FieldTypes;
