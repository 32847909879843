import { Toaster } from "react-hot-toast";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./constants/queryClient";
import useAuthRoutes from "./hooks/useAuthRoutes";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import AuthenticatedProvider from "./components/AuthenticatedProvider";
import { useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const toastOptions = {
  duration: 2000,
  success: {
    duration: 2000,
  },
};

function App() {
  const location = useLocation();
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const routings = useAuthRoutes();

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer></ToastContainer>

      {/* {location.pathname.includes("/admin") ? (
        <Layout>
          <AuthenticatedProvider
            // isAuthenticated={isAuthenticated}
            isAuthenticated={true}
            accounts={accounts}
          >
            <> {routings}</>
          </AuthenticatedProvider>
        </Layout>
      ) : ( */}
        <>{routings}</>
      {/* )} */}
    </QueryClientProvider>
  );
}

export default App;
