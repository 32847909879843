import { Col, Row } from "antd";
import axios from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { successTextPlaceholder, apiKey, postApiKey } from "../constants";
import Header from "./Header";
import QuestionForm from "./QuestionForm";
import { getQuestionnaire, getQuestionnaireInfo } from "../services/api";

const Main = () => {
  const [data, setData] = useState<any>({});
  const [submitted, setSubmitted] = useState(false);
  const [alreadyDone, setAlreadyDone] = useState(false);
  const [questionnaireObj, setQuestionnaireObj] = useState<any>({});
  const [fullValues, setFullValues] = useState(null);
  const [isFully, setIsFully] = useState(false);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const [initVal, setInitVal] = useState(data);
  const textKey = submitted ? "submitted" : "done";
  const successText = successTextPlaceholder[textKey] || "";
  const fetchTemplateData = (callback: (templateData: any) => void) => {
    getQuestionnaire(params.id as string, apiKey).then((res) => {
      if (Object.keys(res.data || {}).length) {
        // res.data["TemplateStyle" as keyof typeof res.data] = {
        //   logo: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5MTguNjY2IiBoZWlnaHQ9IjQwNS4zMzMiIHZpZXdCb3g9IjAgMCA5NTY5IDQyMjIiIHNoYXBlLXJlbmRlcmluZz0iZ2VvbWV0cmljUHJlY2lzaW9uIiB0ZXh0LXJlbmRlcmluZz0iZ2VvbWV0cmljUHJlY2lzaW9uIiBpbWFnZS1yZW5kZXJpbmc9Im9wdGltaXplUXVhbGl0eSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxkZWZzPjxzdHlsZT4uZmlsMHtmaWxsOiNmZWZlZmU7ZmlsbC1ydWxlOm5vbnplcm99PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyX3gwMDIwXzEiPjxwYXRoIGNsYXNzPSJmaWwwIiBkPSJNNjM4OC04YzIwMDMgNzc1IDE0NjAgMjUyMiAxMTIwIDMzMjZoMTkyMUM5NTYyIDI4MTMgMTAzMDEtMjYgNjM4OC04ek00OTggMTU3OHY1ODVoMTA5NmMxNTAgMCAyMTktMjggMjE5LTE2NnYtMjUyYzAtMTM4LTY5LTE2Ni0yMTktMTY2SDQ5OHpNMTkgMTIxN2gxNzk0YzMyMSAwIDQ3MSAxMTQgNDcxIDQwNnY0OTFjMCAyOTItMTUwIDQwNi00NzEgNDA2aC0yNzZsOTY2IDc5MWgtNjk4bC04NDQtNzkxSDQ5OHY3OTFIMTlWMTIxNnpNMjY0MSAxMjE3aDI0MzNsLTE5OCAzNjVIMzEyOHY1NTJoMTQzM2wtMjE1IDM4MkgzMTI4djc5NWgtNDg3ek02MjMyIDI0NzVsLTQ1MC04NTItNDY3IDg1Mmg5MTd6bS02NzAtMTI1OGg0NzFsMTIwMSAyMDk1aC01NDhsLTI1Mi00NTlINTEwN2wtMjQ0IDQ1OWgtNDg3bDExODUtMjA5NXpNOTM1MCAzNTAwTDE4IDM1NjNjLTggMC0xNSA3LTE2IDE1IDAgMC0yIDM3LTIgMzggMCA0IDEgOCA0IDExczcgNSAxMSA1YzAgMCA4ODEzIDEyNCA4ODU3IDEyNC0xNCAzOC0xNjYgNDQ2LTE2NiA0NDYtMSAyLTEgMy0xIDUgMCA1IDMgMTEgNyAxNCAzIDEgNSAyIDggMiA0IDAgOS0yIDEyLTVsNjI5LTY5MWM0LTUgNS0xMSAzLTE3cy04LTEwLTE0LTEweiIvPjwvZz48L3N2Zz4=",
        //   fontFamily:
        //     "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        //   color: "#fff",
        //   backgroundColor: "#103A71",
        //   Labels: {
        //     fontSize: "14px",
        //   },
        //   Headings: {
        //     fontSize: "2.5rem",
        //   },
        // };
        res.data["TemplateStyle" as keyof typeof res.data]=JSON.parse(res.data.templateJSON||{})?.template?.TemplateStyle;
        setQuestionnaireObj({ ...(res.data || {}) });
        callback(JSON.parse(res.data.templateJSON || {}));
      } else {
        setIsFully(true);
        setAlreadyDone(true);
      }
    });
  };
  // const fetchTemplateData = (callback: (templateData: any) => void) => {
  //   callback(temp);
  // }
  useEffect(() => {
    fetchTemplateData((templateData: any) => {
      if (templateData) {
        // Fixes empty tag issue
        templateData.template.pages.forEach((p: any) =>
          p.sections.forEach((s: any) =>
            s.fields.forEach((f: any) => {
              if (f.type === "MultiSelectList") {
                // if (!res.data[f.id]) res.data[f.id] = [];
              }
            })
          )
        );

        // setInitVal({
        //   ...res.data,
        //   dateCompleted: res.data['dateCompleted']
        //     ? moment(res.data['dateCompleted'])
        //     : undefined,
        //   dateOfSeparation: res.data['dateOfSeparation']
        //     ? moment(res.data['dateOfSeparation'])
        //     : undefined
        // });
      } else {
        setAlreadyDone(true);
      }

      setData(templateData);
    });
  }, [searchParams]);

  useEffect(() => {
    if (
      Object.keys(questionnaireObj || {}).length &&
      questionnaireObj["inviteType"] !== "AnonymousInvite"
    ) {
      fetchTemplateData((templateData: any) => {
        getQuestionnaireInfo(params.id as string, postApiKey).then((res) => {
          if (res.status === 200) {
            let newResponse = JSON.parse(res.data.responseJSON || {});
            // Fixes empty tag issue
            templateData.template.pages.forEach((p: any) =>
              p.sections.forEach((s: any) =>
                s.fields.forEach((f: any) => {
                  if (f.type === "MultiSelectList") {
                    if (!newResponse[f.id]) newResponse[f.id] = [];
                  }
                })
              )
            );
            if (questionnaireObj["inviteType"] !== "AnonymousInvite") {
              setInitVal({
                ...newResponse,
                dateCompleted: newResponse["dateCompleted"]
                  ? moment(newResponse["dateCompleted"])
                  : undefined,
                dateOfSeparation: newResponse["dateOfSeparation"]
                  ? moment(newResponse["dateOfSeparation"])
                  : undefined,
              });
            }
          } else {
            setAlreadyDone(true);
          }

          setData(templateData);
        });
      });
    }
  }, [searchParams, Object.keys(questionnaireObj || {}).length]);

  if (isEmpty(data) && !isFully)
    return <span style={{ textAlign: "center" }}>Loading...</span>;

  return (
    <Row className="main-row" style={{fontFamily:questionnaireObj?.TemplateStyle?.fontFamily}as React.CSSProperties}>
      <Col
        className="main-col"
        md={{ span: 16, offset: 4 }}
        sm={{ span: 24, offset: 0 }}
        style={{'--bg-color':questionnaireObj?.TemplateStyle?.backgroundColor,backgroundImage:`url("data:image/jpeg;base64,${questionnaireObj?.TemplateStyle?.backgroundImage}` ,backgroundRepeat:"no-repeat",backgroundSize:"cover"}  as React.CSSProperties}
      >
        {!isFully && <Header title={data} questionnaireObj={questionnaireObj}/>}
        {!submitted && !alreadyDone && !isFully ? (
          <QuestionForm
            data={data}
            handleSubmit={setSubmitted}
            initVal={initVal}
            questionnaireObj={questionnaireObj}
          />
        ) : (
          <h1 className="text-success">{successText}</h1>
        )}
      </Col>
    </Row>
  );
};

export default Main;
