import { ReactComponent as LoaderIcon } from '../assets/icon/spinner.svg';

const Loader = () => {
  return (
    <div className="loader">
      <LoaderIcon width={100} height={100} />
    </div>
  );
};

export default Loader;
